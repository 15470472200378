import React, { useState } from 'react';
import './Cities.css';
import { Helmet } from 'react-helmet';
import EastTNCities from './EastTNCities';
import MiddleEastTNCities from './MiddleEastTNCities'; // Importing Middle East Tennessee cities array

const Cities = () => {
  const [selectedRegion, setSelectedRegion] = useState('east');

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
    const section = document.getElementById(region);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderField = (label, value) => {
    if (typeof value === 'string') {
      // Split space-separated links and filter valid URLs
      const links = value.split(' ').filter((link) => link.startsWith('http'));
      if (links.length > 1) {
        return (
          <li>
            {label}: {links.map((link, idx) => (
              <span key={idx}>
                <a href={link} target="_blank" rel="noopener noreferrer">{`${label} ${idx + 1}`}</a>
                {idx < links.length - 1 && ', '}
              </span>
            ))}
          </li>
        );
      } else if (links.length === 1) {
        return (
          <li>
            {label}: <a href={links[0]} target="_blank" rel="noopener noreferrer">{label}</a>
          </li>
        );
      }
    }
  
    if (Array.isArray(value)) {
      return (
        <li>
          {label}: {value.map((item, idx) => (
            typeof item === 'string' && item.startsWith('http') ?
              <span key={idx}>
                <a href={item} target="_blank" rel="noopener noreferrer">{`${label} ${idx + 1}`}</a>
                {idx < value.length - 1 && ', '}
              </span>
              : <span key={idx}>{item}</span>
          ))}
        </li>
      );
    } else if (typeof value === 'string' && value.startsWith('http')) {
      return (
        <li>
          {label}: <a href={value} target="_blank" rel="noopener noreferrer">{label}</a>
        </li>
      );
    } else if (value) {
      return <li>{label}: {value}</li>;
    }
  
    return null; // Don't display anything if no value exists
  };

  return (
    <div className="cities-page">
 <Helmet>
  <title>Explore Tennessee Cities & Services - Relocate-TN</title>
  <meta name="description" content="Discover Tennessee cities, utilities, and government services. Find information on electricity, water, schools, internet, and moving resources." />
  <meta name="robots" content="index, follow" />
  <meta name="keywords" content="Tennessee cities, moving to Tennessee, Tennessee utilities, Tennessee government services, relocation, public schools, real estate" />
  <meta property="og:title" content="Explore Tennessee Cities & Services - Relocate-TN" />
  <meta property="og:description" content="Find essential city information in Tennessee including utilities, demographics, schools, and government services." />
  <meta property="og:url" content="https://www.relocate-tn.com/cities" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.relocate-tn.com/assets/tennessee-cities.jpg" />
  <link rel="canonical" href="https://www.relocate-tn.com/cities" />
</Helmet>


      <section className="hero-cities">
        <div className="cities-image">
          {/* Your image will go here */}
        </div>
        <div className="overlay-text-cities">
          <h2>Some of the area’s most beautiful land may require special consideration.</h2>
          <p>This website lists utilities and services that are associated with each county/city. Rural areas may not have access to all of these services due to infrastructure limitations.</p>
          <p>Purchase of vacant land in un-incorporated areas may require additional research and expense as services may have to be acquired. The following may need to be considered:</p>
          <ul>
            <li><strong>Sewer</strong> – If sewer services are not available, you will need to obtain a soil perk test, septic permit, and install a septic system.</li>
            <li><strong>Water</strong> – If water services are not available, you will need to obtain and estimate for permitting, drilling, and installing a well.</li>
            <li><strong>Electricity</strong> – If electrical service is not available, you will need to contact local electrical utilities for an estimated cost of providing service to your lot.</li>
            <li><strong>Internet</strong> – Internet can be a challenge in rural areas. We have provided some options for each city based on our research.</li>
            <li><strong>Solid Waste</strong> – Trash pick-up in un-incorporated areas may require that you contract with a local provider or transport your refuse to a central collection site.</li>
          </ul>
        </div>
      </section>

      {/* Region Selector */}
      <div className="region-selector-cities">
        <button onClick={() => handleRegionChange('east')}>East Tennessee</button>
        <button onClick={() => handleRegionChange('middle-east')}>Middle East Tennessee</button>
        <button onClick={() => handleRegionChange('middle-west')}>Middle West Tennessee</button>
        <button onClick={() => handleRegionChange('west')}>West Tennessee</button>
      </div>

      {/* Tennessee City Locator Button */}
      <div className="city-locator-container">
        <p>Still looking for what city to move to?</p>
        <a href="https://tennessee.hometownlocator.com/cities/listcitiesalpha,alpha,a.cfm" target="_blank" rel="noopener noreferrer" className="city-locator-button">
          Tennessee City Locator
        </a>
      </div>

      {/* Section for East Tennessee */}
      <section className="city-section" id="east">
        <h2 className="city-title">East Tennessee Cities</h2>
        {EastTNCities.map((city, index) => (
          <div className="city-item" key={index}>
            <h3>{city.name} – {city.county} – Zip Code: {city.zip}</h3>
            <ul>
              {renderField('Government', city.government)}
              {renderField('Demographics', city.demographics)}
              {renderField('Electric', city.electric)}
              {renderField('Water/Sewer', city.waterSewer)}
              {renderField('Cable/Internet', city.cableInternet)}
              {renderField('Gas', city.gas)} {/* Added Gas field */}
              {renderField('Trash Removal', city.trashRemoval)}
              {city.movingInfo && renderField('Moving Information', city.movingInfo)}
              {renderField('Public Schools', city.publicSchools)}
            </ul>
          </div>
        ))}
      </section>

      {/* Section for Middle East Tennessee */}
      <section className="city-section" id="middle-east">
        <h2 className="city-title">Middle East Tennessee Cities</h2>
        {MiddleEastTNCities.map((city, index) => (
          <div className="city-item" key={index}>
            <h3>{city.name} – {city.county} – Zip Code: {city.zip}</h3>
            <ul>
              {renderField('Government', city.government)}
              {renderField('Demographics', city.demographics)}
              {renderField('Electric', city.electric)}
              {renderField('Water/Sewer', city.waterSewer)}
              {renderField('Cable/Internet', city.cableInternet)}
              {renderField('Gas', city.gas)} {/* Added Gas field */}
              {renderField('Trash Removal', city.trashRemoval)}
              {city.movingInfo && renderField('Moving Information', city.movingInfo)}
              {renderField('Public Schools', city.publicSchools)}
            </ul>
          </div>
        ))}
      </section>

      <section className="city-section" id="middle-west">
        <h2 className="city-title">Middle West Tennessee Cities</h2>
        <p className="coming-soon">Coming Soon</p>
      </section>

      <section className="city-section" id="west">
        <h2 className="city-title">West Tennessee Cities</h2>
        <p className="coming-soon">Coming Soon</p>
      </section>
    </div>
  );
};

export default Cities;
